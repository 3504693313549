@import url(https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css);
@import url(https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css);
@import url(https://fonts.googleapis.com/css2?family=Lato&family=Great+Vibes&family=Comforter+Brush&family=Source+Code+Pro&family=Amatic+SC:wght@400;700&family=Montagu+Slab&family=Yuji+Syuku&family=VT323&family=Press+Start+2P&display=swap);
:root {
  --title-main: #2a71ac;
  --title-mid: #70234a;
  --title-hover: #9c3167;
  --outline-border: #313131;
  --bg-primary: #ffd2d1;
  --bg-light: #fae0e0;
  --bg-alt: #a1ecff;
  --type-fx: #fae0e0;
  --contact-hover: #70234a;
  --contact-text: #313131;
  --bg-transparent: rgba(22, 141, 170, 0.5);
  --transparency: 20%;
  --icon-border: none;
  --box-shadow: -1px -1px 5px rgba(169, 235, 255, 0.884), 1px -1px 5px rgba(169, 235, 255, 0.884), -1px 1px 5px rgba(169, 235, 255, 0.884), 1px 1px 5px rgba(169, 235, 255, 0.884);
  --box-shadow-hover: -1px -1px 10px rgba(255, 132, 95, 0.884), 1px -1px 10px rgba(255, 156, 138, 0.884), -1px 1px 10px rgba(255, 109, 109, 0.884), 1px 1px 10px rgba(255, 147, 115, 0.884);

}

.dark-mode {
  --title-main: #CFFFF4;
  --title-mid: #009979;
  --title-hover: #E7DBFF;
  --outline-border: #CFFFF4;
  --bg-primary: #282828;
  --bg-light: #313131;
  --bg-alt: #CFFFF4;
  --type-fx: #e9e9e9;
  --contact-hover: #CFFFF4;
  --contact-text: #e9e9e9;
  --bg-transparent: rgba(48, 48, 48, 0.541);
  --transparency: 45%;
  --icon-border: 1px solid #EFF;
  --box-shadow: -1px -1px 5px rgba(239, 197, 255, 0.445), 1px -1px 5px rgba(239, 197, 255, 0.445), -1px 1px 5px rgba(239, 197, 255, 0.445), 1px 1px 5px rgba(239, 197, 255, 0.445);
  --box-shadow-hover: -1px -1px 10px rgba(239, 197, 255, 0.445), 1px -1px 10px rgba(239, 197, 255, 0.445), -1px 1px 10px rgba(239, 197, 255, 0.445), 1px 1px 10px rgba(239, 197, 255, 0.445);
}

.mono {
  font-family: "Source Code Pro", monospace;
}

.mono-title {
  font-family: "VT323", monospace;
}

.home-name {
  font-size: 150px;
}

.main-format {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.content-shell2 {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.content-shell {
  position: relative;
  padding-top: 4rem;
  margin-top: 4rem;
  padding-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.bg-img { 
  display: flex;
  object-fit: cover;
  position: fixed;
  width: 100%;
  height: 100%;
}

/*@media (max-width: 1000px) and (orientation: portrait) {
  .bg-img {
    display: flex;
    min-width: 250%;
    height: 100%;
  }
}*/

/*@media (max-width: 400px) and (orientation: portrait) {
  .bg-img {
    display: flex;
    min-width: 300%;
    height: 100%;
  }
}*/

.bg-layer {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
}

.autumn {
  background-image: linear-gradient(to bottom right, rgb(245, 87, 39), rgb(20, 110, 73));
  opacity: 20%;
  opacity: var(--transparency);
  transition: 1.5s;
}

.winter {
  background-image: linear-gradient(to bottom right, rgb(142, 69, 226), rgb(41, 219, 166) );
  opacity: 20%;
  opacity: var(--transparency);
  transition: 1.5s;
}

.summer {
  background-image: linear-gradient(to bottom right, rgb(22, 167, 252), rgb(231, 213, 45));
  opacity: 20%;
  opacity: var(--transparency);
  transition: 1.5s;
}

.spring {
  background-image: linear-gradient(to bottom right, rgb(79, 220, 255), rgb(255, 166, 1));
  opacity: 20%;
  opacity: var(--transparency);
  transition: 1.5s;
}

.dawn {
  background-image: linear-gradient(to bottom right, rgb(193, 241, 255), rgb(0, 38, 141));
  opacity: 20%;
  opacity: var(--transparency);
  transition: 1.5s;
}


/****** NavBar ******/

.nav-main {
  display: flex;
  position: fixed;
  background-color: #ffd2d1;
  background-color: var(--bg-primary);
  min-width: 100%;
  z-index: 2;
  height: 6.5rem;
  transition: background-color 1.5s, color 1.5s;
}

.nav-container {
  z-index: 2;
  margin: 0rem 0rem 0rem 1rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0px 1.2rem;
}

.navlink-src {
  display: inline-flex;
  justify-items: center;
  padding-right: 1.5rem;
  color: #2a71ac;
  color: var(--title-main); 
  font-size: 5rem; 
  font-weight: bold; 
  letter-spacing: 0.1rem;
  text-shadow: -1px -1px 0 #ffd2d1, 1px -1px 0 #ffd2d1, -1px 1px 0 #ffd2d1, 1px 1px 0 #ffd2d1;
  text-shadow: -1px -1px 0 var(--bg-primary), 1px -1px 0 var(--bg-primary), -1px 1px 0 var(--bg-primary), 1px 1px 0 var(--bg-primary);
  transition: color 1.5s, text-shadow 1.5s;
}

.navlink-src:hover {
  color: #9c3167;
  color: var(--title-hover);
  text-shadow: -1px -1px 0px #313131, 1px -1px 0px #313131, -1px 1px 0px #313131, 1px 1px 0px #313131;
  text-shadow: -1px -1px 0px var(--outline-border), 1px -1px 0px var(--outline-border), -1px 1px 0px var(--outline-border), 1px 1px 0px var(--outline-border);
  transition-duration: 0.2s;
}

.nav-small {
  display: none;
}


/****** navlink:hover ******/

.navlink {
  display: inline-flex;
  font-size: 1.1rem;
  justify-items: center;
  padding: 1rem 0.5rem;
  margin: 0 1rem;
  color: #2a71ac;
  color: var(--title-main);
  text-align: left;
  border-top: 0.12rem solid #ffd2d1;
  border-top: 0.12rem solid var(--bg-primary);
  padding-top: 0.2rem;
  transition: color 1.5s;
  transition: color 0.5s, border 1.5s;
}

.navlink:hover {
  color: #9c3167;
  color: var(--title-hover);
  padding-top: 0.2rem;
  border-top: 0.12rem solid #9c3167;
  border-top: 0.12rem solid var(--title-hover);
  transition: color 0.5s, border 0.5s;
}

.nav-social {
  justify-content: space-between;
  align-items: center;
  display: inline-    flex; 
}

.icon {
  border: none;
  border: var(--icon-border);
  border-radius: 100%;
  box-shadow: -1px -1px 5px rgba(169, 235, 255, 0.884), 1px -1px 5px rgba(169, 235, 255, 0.884), -1px 1px 5px rgba(169, 235, 255, 0.884), 1px 1px 5px rgba(169, 235, 255, 0.884);
  box-shadow: var(--box-shadow);
  margin-right: 1rem;
}

.icon:hover {
  box-shadow: -1px -1px 10px rgba(255, 132, 95, 0.884), 1px -1px 10px rgba(255, 156, 138, 0.884), -1px 1px 10px rgba(255, 109, 109, 0.884), 1px 1px 10px rgba(255, 147, 115, 0.884);
  box-shadow: var(--box-shadow-hover);
  transition: 0.25s;
}

.nav-li {
  display: none;
}

/****** Dark Mode Toggle ******/

.theme-switch-wrapper {
  display: inline-flex;
  padding-top: 0.5rem;
  margin-left: auto;
  margin-right: 1.2rem;
  align-items: right;
  justify-items: right;
  flex-direction: column;
}

.theme-switch-wrapper p {
  font-family: "Source Code Pro", monospace;
  padding-top: none;
  color: #9c3167;
  color: var(--title-hover);
  font-size: 0.7rem;
  transition: 1.5s;
}

.theme-switch {
  display: inline-block;
  height: 34px;
  position: relative;
  width: 60px;
}

.theme-switch input {
  display:none;
}

.slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slider:before {
  background-color: #fff;
  bottom: 4px;
  content: "";
  height: 26px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 26px;
}

input:checked + .slider {
  background-color: #222222;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/****** NavBar Tablet ******/

@media (max-width: 900px) {

  .nav-container {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  
  .nav-large {
    display: none;
  }

  .nav-small {
    display: flex;
  }
 
  .hamburger {
    display: inline-flex;
    color: #2a71ac;
    color: var(--title-main);
    width: 45px;
    height: 45px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.3rem;
    border: 1px solid #ffd2d1;
    border: 1px solid var(--bg-primary);
    border-radius: 3px;
    transition: 1.5s;
  }

  .hamburger:hover {
    border: 1px solid #2a71ac;
    border: 1px solid var(--title-main);
    border-radius: 3px;
    transition: 0.25s;
  }

  .nav-li {
    display: block;
    flex-direction: column;
    justify-items: center;
    width: 11rem;
    padding: 1.5rem 0rem;
    margin: 0 1rem;
    color: #2a71ac;
    color: var(--title-main);
    font-size: 1.5rem;
    text-align: left;
    border-top: 2px solid #ffd2d1;
    border-top: 2px solid var(--bg-primary);
    padding-top: 0.2rem;
    transition: color 0.5s, border 0.5s;
  }

  .nav-li:hover {
    color: #70234a;
    color: var(--contact-hover);
    padding-top: 0.2rem;
    border-top: 2px solid #70234a;
    border-top: 2px solid var(--contact-hover);
    transition: color 0.5s, border 0.5s;
  }

  .close {
    display: inline-flex;
    margin: auto;
    width: 45px;
    height: 45px;
    color: #2a71ac;
    color: var(--title-main);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0.3rem;
  }

  .close:hover {
    color: #9c3167;
    color: var(--title-hover);
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    transition-duration: 0.25s;
  }

  .drop {
    display: inline;
    position: fixed;
    margin-top: 5.5rem;
  }

  .dropdown-container {
    display: flex;
    z-index: 1;
    flex-direction: column;
    position: relative;
    width: 16rem;
    background-color: #ffd2d1;
    background-color: var(--bg-primary);
    transition-duration: 0.5s;
    transition: background-color 1.5s;
  }

  .dropdown-menu {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    padding-left: 1.2rem;
    padding-right: 2rem;
    margin-bottom: 0.2rem;
  }
  
  .icon {
    max-height: 45px;
    max-width: 45px;
    margin-right: 1rem;
  }
} 

/****** Navbar Mobile ******/

@media (max-width: 560px) {

  .dropdown-container {
    width: 10rem;
  }

  .dropdown-menu {
    padding-top: 1.5rem;
  }

  .nav-li {
    width: 7.4rem;
    font-size: 1rem;
    margin-left: 0.2rem;
    padding-top: 0.2rem;
    padding-bottom: 0.8rem;
  
  }

  .nav-container {
    padding-left: 0.5rem;
    padding-right: 0.5rem
  }

  .navlink-src {
    font-size: 3.5rem;
    margin-top: auto;
    margin-bottom: auto;  
    padding-right: 0rem;
  }

  .icon {
    max-height: 30px;
    max-width: 30px;
    margin-right: 1rem;
  }

  .dropdown-container {
    transition-duration: 0.5s;
    transition: background-color 1.5s;
  }

  .hamburger {
    margin-top: 1.75rem;
    margin-left: 0.5rem;
    width: 32px;
    height: 32px;
  }

  .close {
    margin-top: 1.74rem;
    margin-left: 0.4rem;
    width: 32px;
    height: 32px;
  }

  .theme-switch-wrapper p {
    font-size: 0.5rem;
    text-align: center;
  }

  .theme-switch {
    display: inline-block;
    height: 28px;
    position: relative;
    width: 50px;
  }
   
  .slider::before {
    width: 20px;
    height: 20px;
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }
  
}

@media (max-width: 360px) {
  .nav-main {
    height: 4.5rem;
  }
  .navlink-src {
    font-size: 2.5rem ;
  }

  .nav-social {
    display: inline-flex;
    flex-direction: row;
  }

  .icon { 
    max-height: 23px;
    max-width: 23px;
    margin-right: 0.5rem;
  }

  .theme-switch-wrapper p {
    font-size: 0.43rem;
    text-align: center;
  }

  .theme-switch {
    display: inline-block;
    height: 22px;
    position: relative;
    width: 40px;
  }
   
  .slider::before {
    width: 14px;
    height: 14px;
  }

  input:checked + .slider:before {
    transform: translateX(18px);
  }

  .hamburger {
    margin-top: 0.92rem;
  }

  .close {
    margin-top: 0.92rem;
  }
}

/****** Home ******/


.home-title {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  min-height: screen;
  padding: 5rem 2rem 0 2rem;
  
}

.home-title-text {
  margin-top: 5rem;
  font-size: 12vw;
  text-align: center;
  text-shadow: 5px 5px #a1ecff;
  text-shadow: 5px 5px var(--bg-alt);
  color: #9c3167;
  color: var(--title-hover);
  display: flex;
  line-height: 1.375;
  transition: color 1.5s;
}

.type-div {
  position: relative;
  display: flex;
  justify-content: right;
  padding-top: 1.5rem;
}

.type-fx {
  background-color: rgba(22, 141, 170, 0.5);
  background-color: var(--bg-transparent);
  border-radius: 5px;
  font-size: 2.5rem;
  color: #fae0e0;
  color: var(--type-fx);
  margin-right: 4rem;
  padding: 0rem 1rem;
  transition: 1.5s;
} 



/****** Home Mobile ******/ 
@media (max-width: 600px) {
  .type-fx {
    font-size: 1.2rem;
  }

  .home-title-text {
    text-shadow: 2px 2px #a1ecff;
    text-shadow: 2px 2px var(--bg-alt);
  }

  .home-title {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 400px) {
  .home-title {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .type-fx {
    font-size: 1.2rem;
  }
}

/****** Blog Posts ******/

.blog-section {
  display: flex;
  position: relative;
  width: auto;
  justify-content: center;
  align-items: center;
}

.blogs-title { 
  display: flex;
  justify-content: center;
  font-size: 8.3vw;
  color: #9c3167;
  color: var(--title-hover);
  padding: 0rem;
  margin-top: 0rem;
  text-shadow: -2px -2px 1px #a1ecff, 2px -2px 1px #ffd2d1, -2px 2px 1px #ffd2d1, 2px 2px 1px #ffd2d1;
  text-shadow: -2px -2px 1px var(--bg-alt), 2px -2px 1px var(--bg-primary), -2px 2px 1px var(--bg-primary), 2px 2px 1px var(--bg-primary);
}



/****** Project Page *******/

.projects-section {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem;
  margin-top: 5.5rem;
  margin-right: 2rem;
  margin-left: 2rem;
}

.projects-title {
  display: flex;
  justify-content: center;
  font-size: 8.3vw;
  color: #9c3167;
  color: var(--title-hover);
  text-shadow: -2px -2px 1px #a1ecff, 2px -2px 1px #ffd2d1, -2px 2px 1px #ffd2d1, 2px 2px 1px #ffd2d1;
  text-shadow: -2px -2px 1px var(--bg-alt), 2px -2px 1px var(--bg-primary), -2px 2px 1px var(--bg-primary), 2px 2px 1px var(--bg-primary);
}

.projects-container {
  display: grid;
  justify-content: center;
  margin-top: 2rem;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 2rem;
  background-color: #ffd2d1;
  background-color: var(--bg-primary); 
  border-radius: 8px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 1.5s;
}

.projects-desc {
  display: flex;
  background-color: #ffd2d1;
  background-color: var(--bg-primary);
  font-size: 1.2rem;
  text-align: center  ;
  color: #2a71ac;
  color: var(--title-main); 
  justify-content: center;
  padding: 0.25rem;
  max-width: 70%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
  transition: 1.5s;
}

.project-summary {
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-template-rows: 1fr 3fr;
  flex-direction: column;
  background-color: #fae0e0;
  background-color: var(--bg-light);
  padding: 1rem;
  margin: 1rem;
  border-radius: 5px;
  transition: background-color 1.5s;
}

.navlink-project {
  display: grid;
  grid-column: 1 / span 1;
  color: #2a71ac;
  color: var(--title-main);
  font-size: 1.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  transition: color 1.5s;
}

.navlink-project:hover {
  color: #9c3167;
  color: var(--title-hover);
  transition: color 0.25s;
}

.project-blurb {
  display: grid;
  grid-row: 2 / span 1;
  justify-content: space-between;
  align-items: top;
  color: #2a71ac;
  color: var(--title-main);
  font-size: 1rem ;
  margin-right: 1rem;
  margin-left: 0rem;
  transition: color 1.5s;
}

.thumb-container {
  position: relative;
  object-fit: cover;
  width: auto;
  height: auto;
  display: block;
  grid-column: 2 / span 1;
  grid-row: 1 / span 2;
  transition: 1s;
}

.project-thumbnail {
  object-fit: cover;
  width: auto;
  height: 100%;
  display: block;
  transition: 1s;
}

.overlay {
  display: flex;
  position: absolute;
  object-fit: cover;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: .5s ease;
  background-color: #ffd2d1;
  background-color: var(--bg-primary);
}

.thumblink-text {
  color: #70234a;
  color: var(--contact-hover);
  font-size: 2rem;
  text-align: center;
  margin: auto;
}

.project-thumbnail:hover {
  box-shadow: -1px -1px 10px rgba(255, 132, 95, 0.884), 1px -1px 10px rgba(255, 156, 138, 0.884), -1px 1px 10px rgba(255, 109, 109, 0.884), 1px 1px 10px rgba(255, 147, 115, 0.884);
  box-shadow: var(--box-shadow-hover);
  transition: 0.2s;
}

.project-thumbnail:hover .overlay {
  opacity: 0.7;
}

/****** Projects Mobile ******/

@media ( max-width: 1100px ) {
  .projects-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .project-summary {
    grid-template-columns: 1fr 1fr;
  }

  .projects-grid {
    grid-template-columns: auto auto;
  }

  .project-article {
    max-width: 450px;
    min-height: 200px;
    max-height: 350px;
  }
}

@media ( max-width: 950px ) {

  .overlay {
    opacity: 0.4;
  }

  .navlink-project {
    font-size: 1.2rem;
  }

  .projects-container {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .projects-desc {
    font-size: 0.9rem;
    max-width: 90%;
  }

  .project-summary {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
  }

  .project-blurb {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .thumb-container {
    grid-column: 1;
    grid-row: 3;;
  }
  
  .project-thumbnail {
    object-fit: cover;
    width: auto;
    height: 100%;
    display: block;
    transition: 1s;
  }
  
}

@media ( max-width: 500px ) {
  .projects-title {
    font-size: 7.5vw;
  }

  .projects-container {
    padding: 0rem;
  }

  .navlink-project {
    font-size: 1.1rem;
  }

  .projects-container {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .projects-desc {
    font-size: 0.75rem;
    max-width: 90%;
    margin-bottom: 0rem;
  }

  .project-blurb {
    text-align: justify;
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .thumb-container {
    grid-column: 1;
    grid-row: 3;;
  }
  
  .project-thumbnail {
    object-fit: cover;
    width: auto;
    height: 100%;
    display: block;
    transition: 1s;
  }

  .thumblink-text {
    font-size: 2rem;
  }
  
}

/****** About Me ******/


.about-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
  margin-left: 5rem;
  margin-right: 5rem;
  padding: 2rem;
  background-color: #ffd2d1;
  background-color: var(--bg-primary); 
  border-radius: 8px; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 1.5s;
}

.about-img {
  display: inline-flex;
  border-radius: 100%;
  justify-content: center;
  width: auto;
  height: auto;
  max-height: 300px;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.bio-div {
  display: inline-flex;
  justify-self: right;
  justify-content: left;
  flex-direction: column;
  padding: 1rem;
  margin-left: 20px;
  background-color: #fae0e0;
  background-color: var(--bg-light);
  transition: 1.5s;
}

.bio-title {
  font-size: 4vw;
  color: #2a71ac;
  color: var(--title-main);
  margin-bottom: 1rem;
  transition: 1.5s;
}

.about-bio {
  text-align: left;
  color: #2a71ac;
  color: var(--title-main);
  font-size: 1.2rem;
  transition: 1.5s;
}

/****** About Mobile ******/

@media (max-width: 1000px) {
  .about-img {
    width: 16rem;
    height: 16rem;
    margin: 1rem;
    margin-top: 0rem;
    margin-bottom: 2rem;
  }
  .bio-div {
    margin-left: auto;
    margin-right: auto;
  }


  .about-container {
    display: flex;
    margin-right: 2rem;
    margin-left: 2rem;
    margin-bottom: 2rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .bio-title {
    font-size: 5.5vw;
    color: #2a71ac;
    color: var(--title-main);
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .about-bio {
    color: #2a71ac;
    color: var(--title-main);
    text-align: center;
    font-size: 1.1rem;
    margin: 1rem;
  }
}

@media (max-width: 800px) {

  .bio-title {
    font-size: 2rem;
  }

  .about-bio {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .about-container {
    padding: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .bio-title {
    font-size: 1.5rem;
  }

  .about-img {
    width: 14rem;
    height: 14rem;
    margin: 1rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .about-bio {
    font-size: 0.75rem;
    margin-left: 0px;
    margin-right: 0px;
  }
}

/****** Contact Me ******/

.contact-me {
  position: relative;
  background-color: #ffd2d1;
  background-color: var(--bg-primary);
  margin-top: 3rem;
  margin-left: 8vw;
  margin-right: 8vw;
  padding-bottom: 2rem;
  height: auto;
  border-radius: 15px;
  text-align: center;
  transition: 1.5s;
}

.contact-header {
  padding-top: 1rem;
  font-size: 3rem;
  color: #2a71ac;
  color: var(--title-main);
  transition: color 1.5s;
}

.contact-desc {
  color: #2a71ac;
  color: var(--title-main);
  font-size: 1rem;
  text-align: center;
  width: 70%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  padding-bottom: 1.5rem;
  transition: color 1.5s;
}

.contact-grid {
  display: grid;
  max-height: 40%;
  grid-template-columns: 1fr 2fr;
  grid-auto-flow: row dense;
  grid-gap: 20px;
  padding-left: 3rem;
  justify-items: start;
}

.contact-input {
  display: block;
  color: #313131;
  color: var(--contact-text);
  background-color: #fae0e0;
  background-color: var(--bg-light);
  border-bottom: 2px solid #ffd2d1;
  border-bottom: 2px solid var(--bg-primary);
  min-width: 100px;
  size: 28;
  transition: 1.5s;
 }

.contact-input:focus {
  outline: none;
  color: #2a71ac;
  color: var(--title-main);
  border-bottom: 2px solid #9c3167;
  border-bottom: 2px solid var(--title-hover);
  transition: 0.5s;
 }

.contact-body {
  resize: none;
  display: block;
  width: 100%;
  min-width: 40%;
  min-height: 170px;
  background-color: #fae0e0;
  background-color: var(--bg-light);
  border-bottom: 2px solid #ffd2d1;
  border-bottom: 2px solid var(--bg-primary);
  transition: 1.5s;
 }

.contact-body:focus {
  outline: none;
  color: #313131;
  color: var(--outline-border);
  border-bottom: 2px solid #9c3167;
  border-bottom: 2px solid var(--title-hover);
  transition: 0.5s;
}

.contact-button {
  display: block;
  color: #2a71ac;
  color: var(--title-main);
  text-align: left;
  min-width: 100%;
  border-bottom: 2px solid #ffd2d1;
  border-bottom: 2px solid var(--bg-primary);
  transition: 1.5s;
}

.contact-button:hover {
  outline: none;
  color: #9c3167;
  color: var(--title-hover);
  border-bottom: 2px solid #9c3167;
  border-bottom: 2px solid var(--title-hover);
  transition: 0.5s;
}


.grid-1 {
  grid-column: 1;
  grid-row: 1;
}

.grid-2 {
  grid-column: 1;
  grid-row: 2;
}

.grid-3 {
  grid-column: 1;
  grid-row: 3;
}

.grid-4 {
  grid-column: 1;
  grid-row: 4;
}

.grid-5 {
  width: 100%;
  padding-right: 3rem;
  grid-column: 2;
  grid-row: span 4;
}

.grid-6 {
  width: 100%;
  padding-right: 3rem;
  grid-column: 2;
  grid-row: 5;
}

/****** Contact Mobile ******/

@media (max-width: 900px) {
  
  .contact-me {
    margin-bottom: 4rem;
  }

  .contact-header {
    font-size: 2.5rem;
  }

  .contact-desc {
    font-size: 0.9rem;
    width: 90%;
  }

  .contact-input, .contact-body, .contact-button {
    font-size: 0.9rem;
  }

  .contact-grid {
    display: grid;
    max-height: 40%;
    grid-template-columns: 1fr;
    padding-left: 0rem;
    justify-items: center;
  }

  .contact-button {
    text-align: left;
    width: 70%;
  }

  .grid-5 {
    width: 70%;
    padding-right: 0rem;
    grid-column: 1;
    grid-row: 5;
  }
  
  .grid-6 {
    width: 70%;
    padding: 0rem;
    grid-column: 1;
    grid-row: 6;
  }
}

@media (max-width: 500px) {
  .contact-me {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .contact-header {
    font-size: 2rem;
  }

  .contact-desc {
    font-size: 0.7rem;
  }

  .contact-input, .contact-body, .contact-button {
    font-size: 0.7rem;
  }

  .grid-5, .grid-6 {
    width: 85%;
  }

}
